import request from '../utils/request'

export const add_permissions=(company,branch_content,branch_level,account,password,phone,name)=>{
    return request({
        url:'/admin/permissions',
        method:'post',
        data:{
            company,
            branch_content,
            branch_level,
            account,
            password,
            phone,
            name,
        }
    })
}
export const permissions_list=(company,account,branch_content,pagenum,pagesize)=>{
    return request({
        url:'/admin/permissions',
        method:'get',
        params:{
            company,
            account,
            branch_content,
            pagenum,
            pagesize
        }
    })
}
export const remove_permissions=(company,id,branch_content)=>{
    return request({
        url:'/admin/permissions',
        method:'delete',
        data:{
            company,
            id,
            branch_content
        }
    })
}
export const edit_permissions=(company,branch_content,branch_level,account,phone,name,status,id,old_branch_content)=>{
    return request({
        url:'/admin/permissions',
        method:'put',
        params:{
            company,
            branch_content,
            branch_level,
            account,
            phone,
            name,
            status,
            id,
            old_branch_content
        }
    })
}
export const status_permissions=(company,id,status)=>{
    return request({
        url:'/admin/permissions',
        method:'patch',
        data:{
            company,
            id,
            status
        }
    })
}
export const get_branch=(company,branch_level,branch_content)=>{
    return request({
        url:'/admin/get_branch',
        method:'post',
        data:{
            company,
			branch_content,
			branch_level,
        }
    })
}
