<template>
    <div class="container">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>管理后台</el-breadcrumb-item>
            <el-breadcrumb-item>权限管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-row>
                <el-col :span="22">
                    <el-form :inline="true">
                        <el-form-item>
                            <el-input size="small" placeholder="账号" v-model="searchAccount"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-select v-model="searchDepartment1" size="small" placeholder="一级部门" @change="choiceA">
                                <el-option
                                v-for="item in department1List"
                                :key="item.branch"
                                :label="item.branch"
                                :value="item.branch">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-select v-model="searchDepartment2" v-if="department2List.length>0" size="small" placeholder="二级部门" @change="choiceB">
                                <el-option
                                v-for="item in department2List"
                                :key="item.branch"
                                :label="item.branch"
                                :value="item.branch">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-select v-model="searchDepartment3" v-if="department3List.length>0" size="small" placeholder="三级部门" @change="choiceC">
                                <el-option
                                v-for="item in department3List"
                                :key="item.branch"
                                :label="item.branch"
                                :value="item.branch">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-select v-model="searchDepartment4" v-if="department4List.length>0" size="small" placeholder="四级部门" @change="choiceD">
                                <el-option
                                v-for="item in department4List"
                                :key="item.branch"
                                :label="item.branch"
                                :value="item.branch">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" size="small"  @click="search">查询</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" size="small"  @click="reset">重置</el-button>
                        </el-form-item>                       
                    </el-form>
                </el-col>
                <el-col :span="2">
                    <el-form :inline="true">
                        <el-form-item>
                            <el-button type="primary" size="small"  @click="add">添加管理员</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
            <el-table
                :data="permissionsData"
                border
                stripe
                style="width: 100%">
                <el-table-column
                    prop="account"
                    label="账号">
                </el-table-column>
                <el-table-column
                    prop="name"
                    label="姓名">
                </el-table-column>
                <el-table-column
                    prop="phone"
                    label="联系电话">
                </el-table-column>
                <el-table-column
                    prop="branch_level"
                    label="角色">
                    <template slot-scope="scope">
                        {{formatLevel(scope.row.branch_level)}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="branch_content"
                    label="权限范围">
                </el-table-column>
                <el-table-column
                    prop="status"
                    label="状态">
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.status"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            active-value="open"
                            inactive-value="close"
                            @change="adminStatus(scope.row.status,scope.row.id)">
                        </el-switch>
                    </template>
                </el-table-column>
				<el-table-column label="权限菜单范围">
					<template slot-scope="scope">
						{{formatpowerlist(scope.row.powers)}}
					</template>
				</el-table-column>
                <el-table-column
                    label="操作">
                    <template slot-scope="scope">
                        <el-button type="success" size="mini" @click="edit(scope.row)">编辑</el-button>
                        <el-button type="danger"  size="mini" @click="remove(scope.row)">删除</el-button>
						<div style="margin-top: 5px;"><el-button size="mini" type="primary" @click="editadmin(scope.row)">权限设置</el-button></div>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pagenum"
                :page-sizes="[5,10,15,20]"
                :page-size="pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination> 
        </el-card>
        <el-dialog
            title="添加管理员"
            :visible.sync="addVisible"
            width="50%">
            <div>
                <el-form ref="form"  label-width="120px">
                    <el-form-item label="姓名">
                        <el-input v-model="addName" placeholder="请输入姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="电话号码">
                        <el-input v-model="addPhone" placeholder="输入电话号码，也是登录账号"></el-input>
                    </el-form-item>
                    <el-form-item label="选择部门" >
                        <el-cascader
                            v-model="selectedDepartment"
                            :options="p_data"
                            :props="cascaderProps"
                            @change="parentDepartmentChanged" 
                            :clearable="true" :change-on-select="true">
                        </el-cascader>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="info" @click="closeAdd">取消</el-button>
                <el-button type="primary" @click="addAccount">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="编辑管理员"
            :visible.sync="editVisible"
            width="50%">
            <div>
                <el-form ref="form"  label-width="120px">
                    <el-form-item label="姓名">
                        <el-input v-model="editName" placeholder="请输入姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="电话号码">
                        <el-input v-model="editPhone" placeholder="输入电话号码，也是登录账号"></el-input>
                    </el-form-item>
                    <el-form-item label="选择部门" >
                        <el-cascader
                            v-model="selectedDepartmentEdit"
                            :options="p_data"
                            :props="cascaderProps"
                            @change="parentDepartmentChangedEdit" 
                            :clearable="true" :change-on-select="true">
                        </el-cascader>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="info" @click="closeEdit">取消</el-button>
                <el-button type="primary" @click="editAccount">确 定</el-button>
            </span>
        </el-dialog>
		<el-dialog title="权限编辑" :visible.sync="poserVisible" width="780px">
			<div>
				<el-transfer :titles="['未拥有权限', ' 赋予该级的权限']" :button-texts="['加入左侧', '加入右侧']" v-model="powerlistChange" :data="powerlist"></el-transfer>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="info" @click="poserVisible = false">取消</el-button>
				<el-button type="primary" @click="editPower">确 定</el-button>
			</span>
		</el-dialog>
    </div>
</template>
<script>
	import {
		// add_team,
		// team_list,
		// team_status,
		editpower
	} from '../api/team'
import {department_list} from '../api/department'
import {bian_log} from '../api/log'
import {add_permissions,permissions_list,remove_permissions,status_permissions,edit_permissions} from '../api/permissions'
export default {
    data() {
        return {
            company:'',
            companyData:[],
            pagenum:1,
            pagesize:10,
            total:0,
            searchAccount:null,
            branch_content:'',
            department1List:[],
            department2List:[],
            department3List:[],
            department4List:[],
            searchDepartment1:null,
            searchDepartment2:null,
            searchDepartment3:null,
            searchDepartment4:null,
            permissionsData:[],
            addVisible:false,
            addName:'',
            addPhone:'',
            addLevel:'',
            p_data:[],
            cascaderProps:{
                value:'branch',
                label:'branch',
                children:'children',
                expandTrigger:'hover'
            },
            selectedDepartment:[],
            addBranchContent:'',
            editVisible:false,
            editName:'',
            editPhone:'',
            editLevel:'',
            editId:'',
            editStatus:'',
            editBranchContent:'',
            selectedDepartmentEdit:[],
            old_branch_content:'',
			poserVisible:false,
			powerlist:[],
			powerlistChange:[],
        };
    },
    computed: {},
    methods: {
		editPower() {
			let arr = [];
			this.powerlist.forEach(item=>{
				this.powerlistChange.forEach(itemc=>{
					if(itemc == item.key){
						arr.push(item)
					}
				})
			})
			editpower({id:this.editid,power:arr}).then(res=>{
				if (res.status == 200) {
					this.poserVisible = false;
					this.permissionsList();
					return this.$message.success('编辑成功！')
				}
				return this.$message.error('编辑失败！')
			})
		},
		editadmin(d){
			this.editid = d.id;
			this.poserVisible = true;
			let powerlist = JSON.parse(d.powers);
			this.powerlist=JSON.parse(d.allpowers);
			this.powerlistChange = [];
			powerlist.forEach((item) =>{
				if(![2,7,8].includes(item.key)){
					this.powerlistChange.push(item.key)
				}
			})
			for(let i in this.powerlist){
				if([1,2,7,8,11].includes(this.powerlist[i].key)){
					this.powerlist[i].disabled = true;
				}
			}

		},
        departmentList(){
            department_list(this.company,this.searchDepartment1,this.searchDepartment2,this.searchDepartment3,this.searchDepartment4,).then(res=>{
                if(res.status!=200){
                    return this.$message.error('获取部门列表失败')
                }
                let branch=res.data.branch;
                branch.forEach(item=>{
                    item.children=JSON.parse(item.children)
                })
                this.companyData=branch;
                this.department1List=branch;
                this.p_data=branch;                
            })
        },
		formatpowerlist(d) {
			if(d){
				let list = JSON.parse(d)
				let arr = [];
				for(let i in list){
					if([1,2,7,8,11].includes(list[i].key)) continue;
					arr.push(list[i].label)
				}
				return arr.join('、');
			}else{
				return "";
			}
		},
        permissionsList(){
            permissions_list(this.company,this.searchAccount,this.branch_content,this.pagenum,this.pagesize).then(res=>{
                if(res.status!=200){
                    return this.$message.error('获取权限列表失败')
                }
                this.permissionsData=res.data.permissionsData
                this.total=res.data.total
            })
        },
        handleSizeChange(newSize){
            this.pagesize=newSize;
            this.permissionsList();
        },
        handleCurrentChange(newPage){
            this.pagenum=newPage;
            this.permissionsList();
        },
        formatLevel(level){
            if(level==2){
                return '一级管理员'
            }
            if(level==3){
                return '二级管理员'
            }
            if(level==4){
                return '三级管理员'
            }
            if(level==5){
                return '四级管理员'
            }
        },
        choiceA(){
            this.branch_content=this.searchDepartment1
            this.searchDepartment2=null
            this.searchDepartment3=null
            this.searchDepartment4=null
            this.department2List=[]
            this.department3List=[]
            this.department4List=[]
            this.department1List.forEach(item=>{
                if(item.branch==this.searchDepartment1){
                    this.department2List=item.children
                }
            })
        },
        choiceB(){
            this.branch_content=this.searchDepartment1+'|'+this.searchDepartment2
            this.searchDepartment3=null
            this.searchDepartment4=null
            this.department3List=[]
            this.department4List=[]
            this.department2List.forEach(item=>{
                if(item.branch==this.searchDepartment2){
                    this.department3List=item.children
                }
            })
        },
        choiceC(){
            this.branch_content=this.searchDepartment1+'|'+this.searchDepartment2+'|'+this.searchDepartment3
            this.searchDepartment4=null
            this.department4List=[]
            this.department3List.forEach(item=>{
                if(item.branch==this.searchDepartment3){
                    this.department4List=item.children
                }
            })
        },
        choiceD(){
            this.branch_content=this.searchDepartment1+'|'+this.searchDepartment2+'|'+this.searchDepartment3+'|'+this.searchDepartment4
        },
        search(){
            this.permissionsList()
        },
        reset(){
            let level=sessionStorage.getItem('level')
            if(level==1){
                this.branch_content=null
            }else{
                this.branch_content=sessionStorage.getItem('branch-content')
            }   
            this.searchAccount=null;
            this.searchDepartment1=null
            this.searchDepartment2=null
            this.searchDepartment3=null
            this.searchDepartment4=null
            this.department2List=[]
            this.department3List=[]
            this.department4List=[]
            this.permissionsList()
        },
        add(){
            this.addVisible=true
        },
        parentDepartmentChanged(){
            if(this.selectedDepartment.length==1){
                this.addBranchContent=this.selectedDepartment[0];
                this.addLevel=2
            }
            if(this.selectedDepartment.length==2){
                this.addLevel=3
            }
            if(this.selectedDepartment.length==3){
                this.addLevel=4
            }
            if(this.selectedDepartment.length==4){
                this.addLevel=5
            }
            if(this.selectedDepartment.length>1){
                this.addBranchContent=this.selectedDepartment.join('|')
            }
        },
        closeAdd(){
            this.addVisible=false;
            this.addPhone='';
            this.addName='';
            this.addLevel='';
            this.selectedDepartment=[];
            this.addBranchContent='';
        },
        addAccount(){
            if(this.addName==''){
                return this.$message.error('请输入姓名')
            }
            if(this.addPhone==''){
                return this.$message.error('请输入电话')
            }
            const regex = /^1[3456789]\d{9}$/;
            if (!regex.test(this.addPhone)){
                return this.$message.error('请输入正确的电话号');    
            }
            if(this.selectedDepartment.length==0){
                return this.$message.error('请选择部门')
            }
            let password=this.addPhone.slice(5)
            add_permissions(this.company,this.addBranchContent,this.addLevel,this.addPhone,password,this.addPhone,this.addName).then(res=>{
                if(res.status==400){
                    return this.$message.error('此账号已被注册')
                }
                if(res.status!=200){
                    return this.$message.error('添加权限失败')
                }
                this.addVisible=false;
                this.addPhone='';
                this.addName='';
                this.addLevel='';
                this.selectedDepartment=[];
                this.addBranchContent='';
                this.permissionsList()
				bian_log(`添加管理员${this.addName}`)
            })
        },
        edit(row){
            this.editVisible=true;
            this.editName=row.name;
            this.editPhone=row.phone;
            this.editId=row.id;
            this.editStatus=row.status;
            this.selectedDepartmentEdit=[];
            this.selectedDepartmentEdit=row.branch_content.split('|')
            this.old_branch_content=row.branch_content
            if(this.selectedDepartmentEdit.length==1){
                this.editBranchContent=this.selectedDepartmentEdit[0];
                this.editLevel=2
            }
            if(this.selectedDepartmentEdit.length==2){
                this.editLevel=3
            }
            if(this.selectedDepartmentEdit.length==3){
                this.editLevel=4
            }
            if(this.selectedDepartmentEdit.length==4){
                this.editLevel=5
            }
            if(this.selectedDepartmentEdit.length>1){
                this.editBranchContent=this.selectedDepartmentEdit.join('|')
            }
        },
        adminStatus(status,id){
            status_permissions(this.company,id,status).then(res=>{
                if(res.status==200){
                    this.$message.success('设置成功')
                }   
            })
        },
        parentDepartmentChangedEdit(){
            if(this.selectedDepartmentEdit.length==1){
                this.editBranchContent=this.selectedDepartmentEdit[0];
                this.editLevel=2
            }
            if(this.selectedDepartmentEdit.length==2){
                this.editLevel=3
            }
            if(this.selectedDepartmentEdit.length==3){
                this.editLevel=4
            }
            if(this.selectedDepartmentEdit.length==4){
                this.editLevel=5
            }
            if(this.selectedDepartmentEdit.length>1){
                this.editBranchContent=this.selectedDepartmentEdit.join('|')
            }
        },
        closeEdit(){
            this.editVisible=false;
            this.editPhone='';
            this.editName='';
            this.editLevel='';
            this.editId='';
            this.editStatus='';
            this.selectedDepartmentEdit=[];
            this.editBranchContent='';
        },
        editAccount(){
            if(this.editName==''){
                return this.$message.error('请输入姓名')
            }
            if(this.editPhone==''){
                return this.$message.error('请输入电话')
            }
            const regex = /^1[3456789]\d{9}$/;
            if (!regex.test(this.editPhone)){
                return this.$message.error('请输入正确的电话号');    
            }
            if(this.selectedDepartmentEdit.length==0){
                return this.$message.error('请选择部门')
            }
            edit_permissions(this.company,this.editBranchContent,this.editLevel,this.editPhone,this.editPhone,this.editName,this.editStatus,this.editId,this.old_branch_content).then(res=>{
                if(res.status!=200){
                    return this.$message.error('编辑权限失败')
                }
                this.editVisible=false;
                this.editPhone='';
                this.editName='';
                this.editLevel='';
                this.editId='';
                this.editStatus='';
                this.selectedDepartmentEdit=[];
                this.editBranchContent='';
                this.permissionsList()
				bian_log(`添加管理员${this.addName}`)
            })
        },
        remove(row){
            this.$confirm('此操作将永久删除该权限', '是否确定删除权限', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                remove_permissions(this.company,row.id,row.branch_content).then(res=>{
                    if(res.status!=200){
                        return this.$message.error('删除权限失败')
                    }
					bian_log(`删除管理员${row.name}`)
                    this.permissionsList()
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });   
        }
    },
    created() {
        let company=sessionStorage.getItem('company')
        this.company=company;
        let level=sessionStorage.getItem('level')
        if(level==1){
            this.branch_content=null
        }else{
            this.branch_content=sessionStorage.getItem('branch-content')
        }        
        this.departmentList()
        this.permissionsList()
    },
};
</script>
<style lang='less' scoped>
.el-cascader{
    width: 100%;
}
</style>