import request from '../utils/request'
export const editpower=(data)=>{
    return request({
        url:'/admin/editpower',
        method:'post',
        data
    })
}
export const add_team=(company,branch_level,account,password,name)=>{
    return request({
        url:'/admin/team',
        method:'post',
        data:{
            company,
            branch_level,
            account,
            password,
            name
        }
    })
}
export const editadmin=(data)=>{
    return request({
        url:'/admin/editadmin',
        method:'post',
        data
    })
}
export const team_list=(pagenum,pagesize)=>{
    return request({
        url:'/admin/team',
        method:'get',
        params:{
            pagenum,
            pagesize
        }
    })
}
export const team_status=(status,id)=>{
    return request({
        url:'/admin/team',
        method:'put',
        data:{
            status,
            id
        }
    })
}